import { ProductInBasket } from '@/models/productInBasket'
import { parseArticleDate } from '@/utils/utils'
import { UserDataContractor } from '@/models/userDataContractor'
import store from '@/store/index'
import { computed } from 'vue'
import Decimal from 'decimal.js'

export class ShoppingCart {
  constructor (shoppingCart = {}) {
    this.id = shoppingCart.id
    this.name = shoppingCart.name
    this.isChanged = shoppingCart.isChanged
    this.master = shoppingCart.master
    this.initDate = parseArticleDate(shoppingCart.initDate)
    this.lastModificationDate = parseArticleDate(shoppingCart.lastModificationDate)
    this.shopUserName = shoppingCart.shopUserName
    this.shopUserDto = new UserDataContractor(shoppingCart.shopUserDto)
    this.orderCommodities = this.parseProducts(shoppingCart.orderCommodities)
    this.shopOrderStatus = shoppingCart.shopOrderStatus
    this.shippingAmount = shoppingCart.shippingAmount
    this.totalAmount = shoppingCart.totalAmount ?? 0
    this.totalAmountWithoutDiscount = shoppingCart.totalAmountWithoutDiscount ?? 0
    this.ownDelivery = shoppingCart.ownDelivery
    this.isCredit = shoppingCart.isCredit
    this.unavailableCart = shoppingCart.unavailableCart
    this.withUnavailableOrder = shoppingCart.withUnavailableOrder
    this.unavailableOrderId = shoppingCart.unavailableOrderId
    this.netto = this.getTotalNettoPriceForStepTwo()
    this.brutto = this.getTotalBruttoPriceForStepTwo()
    this.contractorId = shoppingCart.contractorId
    this.shopUserId = shoppingCart.shopUserId
  }

  parseProducts (products) {
    const productsList = []
    if (products !== undefined) {
      for (const product of products) {
        productsList.push(new ProductInBasket(product))
      }
    } else {
      return productsList
    }

    return productsList
  }

  getTotalBruttoPrice (visible) {
    const priceStatus = computed(() => store.getters['productStore/getIsAfterDiscount'])
    if (this.orderCommodities === null || this.orderCommodities.length === 0) return 0
    let totalPrice = 0
    if (priceStatus.value) {
      // this.orderCommodities.forEach((product) => {
      //   const price = visible ? product.unitPrice : product.commodity.net
      //   totalPrice = new Decimal(totalPrice).add(new Decimal(new Decimal(new Decimal(product.baseUnitPerIndivisible).times(price).toDecimalPlaces(2)).times(product.indivisibleQuantity)).times(new Decimal(1).add(new Decimal(product.commodity.vatValue).dividedBy(100))).toDecimalPlaces(2)).toNumber()
      // })
      totalPrice = this.totalAmount
    } else {
      totalPrice = this.totalAmountWithoutDiscount
      // this.orderCommodities.forEach((product) => {
      //   const price = product.commodity.baseUnitPrice
      //   totalPrice = new Decimal(totalPrice).add(new Decimal(new Decimal(new Decimal(product.baseUnitPerIndivisible).times(price).toDecimalPlaces(2)).times(product.indivisibleQuantity)).times(new Decimal(1).add(new Decimal(product.commodity.vatValue).dividedBy(100))).toDecimalPlaces(2)).toNumber()
      // })
    }
    return new Decimal(totalPrice).toDecimalPlaces(2).toNumber()
  }

  getTotalBruttoPriceForStepTwo () {
    if (this.orderCommodities === null || this.orderCommodities.length === 0) return 0
    let totalBruttoPrice = 0
    totalBruttoPrice = this.totalAmount
    return new Decimal(totalBruttoPrice).toDecimalPlaces(2).toNumber()
  }

  getTotalBruttoPriceForPreview () {
    if (this.orderCommodities === null || this.orderCommodities.length === 0) return 0
    let totalBruttoPrice = 0
    totalBruttoPrice = this.totalAmountWithoutDiscount
    return new Decimal(totalBruttoPrice).toDecimalPlaces(2).toNumber()
  }

  getTotalNettoPrice (visible) {
    const priceStatus = computed(() => store.getters['productStore/getIsAfterDiscount'])
    if (this.orderCommodities === null || this.orderCommodities.length === 0) return 0
    let totalNettoPrice = 0
    if (priceStatus.value) {
      this.orderCommodities.forEach((product) => {
        const price = visible ? product.commodity.discountNet : product.commodity.net
        totalNettoPrice = new Decimal(totalNettoPrice).add(new Decimal(new Decimal((product.baseUnitPerIndivisible)).toDecimalPlaces(2) * price).toDecimalPlaces(2).times(product.indivisibleQuantity)).toNumber()
      })
    } else {
      this.orderCommodities.forEach((product) => {
        const price = product.commodity.net
        totalNettoPrice = new Decimal(totalNettoPrice).add(new Decimal(new Decimal((product.baseUnitPerIndivisible)).toDecimalPlaces(2) * price).toDecimalPlaces(2).times(product.indivisibleQuantity)).toNumber()
      })
    }
    return new Decimal(totalNettoPrice).toDecimalPlaces(2).toNumber()
  }

  getTotalNettoPriceForStepTwo () {
    if (this.orderCommodities === null || this.orderCommodities.length === 0) return 0
    let totalNettoPrice = 0
    this.orderCommodities.forEach((product) => {
      const price = product.commodity.discountNet
      totalNettoPrice = new Decimal(totalNettoPrice).add(new Decimal(new Decimal((product.baseUnitPerIndivisible)).toDecimalPlaces(2) * price).toDecimalPlaces(2).times(product.indivisibleQuantity)).toNumber()
    })
    return new Decimal(totalNettoPrice).toDecimalPlaces(2).toNumber()
  }
}
