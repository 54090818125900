import { computed } from 'vue'
import sanitizeHtml from 'sanitize-html'
import { userRoleInterfaceEnum } from '@/enums/userRoleEnum'
import { filterCharacteristicNameEnum } from '@/enums/filterCharacteristicNameEnum'
import router from '@/router'
import Decimal from 'decimal.js'

export const activedRoutes = []
export const start = 0
export const end = 9

export const createComputedWrapper = (map, key) => {
  return computed({
    get: () => map[key],
    set: (value) => {
      map[key] = value
      if (value !== undefined) {
        map[key] = value
      } else {
        map[key] = ''
      }
    }
  })
}

export const generateRandomOrdersList = () => {
  const orders = []

  for (let i = 0; i < 100; i++) {
    let deliveryService = ''
    let orderer = ''
    if (i % 2 === 0) {
      deliveryService = 'DPD'
      orderer = 'Jan Kowalski'
    } else if (i % 3 === 0) {
      deliveryService = 'UPC'
      orderer = 'Wojciech Nowak'
    } else {
      deliveryService = 'InPost'
      orderer = 'Janusz Wiśniewski'
    }

    orders.push({
      id: i,
      orderNumber: generateRandomNumber(10000000, 40000000),
      name: orderer,
      trackingNumber: generateRandomNumber(10000, 99999),
      deliveryService: deliveryService,
      orderDate: generateRandomDate(),
      realisationDate: generateRandomDate()
    })
  }

  return orders
}

export const generateRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min
}

export const generateRandomDate = () => {
  const year = generateRandomNumber(1999, 2027)
  let month = generateRandomNumber(1, 12)
  let day = generateRandomNumber(1, 28)

  if (day.toString().length < 2) {
    day = '0' + day
  }

  if (month.toString().length < 2) {
    month = '0' + month
  }

  return day + '.' + month + '.' + year
}

export const formatDate = (date) => {
  let month = date.getMonth() + 1
  let day = date.getDate()

  if (month < 10) {
    month = '0' + month
  }

  if (day < 10) {
    day = '0' + day
  }

  return day + '.' + month + '.' + date.getFullYear() + 'r.'
}

export const filterDate = (value, filter) => {
  if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
    return 'true'
  }

  if (value === undefined || value === null) {
    return false
  }

  return value === formatDate(filter)
}

export function sleepOneSec () {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve('resolved')
    }, 3000)
  })
}

export async function handleRequestError (error) {
  let rejectError = ''
  if (error.response.data.message) {
    rejectError = { status: error.response.status, message: error.response.data.message }
  } else {
    rejectError = { status: error.response.status, message: 'Kod błędu: ' + error.response.status + ', wiadomość: ' + error.response.statusText }
  }

  if (error.response.status === 401) {
    // TODO Logout user
  }

  return Promise.reject(rejectError)
}

export function translateStatusRole (statusRole) {
  switch (statusRole) {
    case 'NEW':
      return 'Niewysłany'
    case 'PENDING':
      return 'W trakcie'
    case 'SENT':
      return 'Wysłany'
    default:
      return ''
  }
}

export function translateOrderStatus (statusRole) {
  switch (statusRole) {
    case 'IN_PROGRESS':
      return 'W trakcie'
    case 'PAID':
      return 'Opłacone'
    default:
      return '-'
  }
}

export function translateCharacteristicName (name) {
  switch (name) {
    case 'price':
      return filterCharacteristicNameEnum.price
    case 'availability':
      return filterCharacteristicNameEnum.availability
    case 'isAvailability':
      return filterCharacteristicNameEnum.isAvailability
    case 'waterResistance':
      return filterCharacteristicNameEnum.waterResistance
    case 'lock':
      return filterCharacteristicNameEnum.lock
    case 'spacing':
      return filterCharacteristicNameEnum.spacing
    case 'color':
      return filterCharacteristicNameEnum.color
    case 'thickness':
      return filterCharacteristicNameEnum.thickness
    case 'abrasionClass':
      return filterCharacteristicNameEnum.abrasionClass
    case 'headShape':
      return filterCharacteristicNameEnum.headShape
    case 'socket':
      return filterCharacteristicNameEnum.socket
    case 'profile':
      return filterCharacteristicNameEnum.profile
    case 'commodity_length':
      return filterCharacteristicNameEnum.commodity_length
    case 'antibacterial':
      return filterCharacteristicNameEnum.antibacterial
    case 'collection':
      return filterCharacteristicNameEnum.collection
    case 'thread':
      return filterCharacteristicNameEnum.thread
    case 'type':
      return filterCharacteristicNameEnum.type
    case 'structure':
      return filterCharacteristicNameEnum.structure
    case 'moistureResistance':
      return filterCharacteristicNameEnum.moistureResistance
    case 'openingAngle':
      return filterCharacteristicNameEnum.openingAngle
    case 'material':
      return filterCharacteristicNameEnum.material
    case 'diameter':
      return filterCharacteristicNameEnum.diameter
    case 'kit':
      return filterCharacteristicNameEnum.kit
    case 'width':
      return filterCharacteristicNameEnum.width
    case 'producer':
      return filterCharacteristicNameEnum.producer
    case 'bearingCapacity':
      return filterCharacteristicNameEnum.bearingCapacity
    case 'silentClosing':
      return filterCharacteristicNameEnum.silentClosing
    case 'height':
      return filterCharacteristicNameEnum.height
    case 'colorOfLight':
      return filterCharacteristicNameEnum.colorOfLight
    case 'powerFactor':
      return filterCharacteristicNameEnum.powerFactor
    case 'hiddenWiring':
      return filterCharacteristicNameEnum.hiddenWiring
    case 'symbol':
      return filterCharacteristicNameEnum.symbol
    case 'intendedUse':
      return filterCharacteristicNameEnum.intendedUse
    case 'guaranteePeriod':
      return filterCharacteristicNameEnum.guaranteePeriod
    default:
      return ''
  }
}

export function translateItemStatus (itemStatus) {
  switch (itemStatus) {
    case 'AVAILABLE':
      return 'Dostępny'
    case 'NOT_AVAILABLE':
      return 'Niedostępny'
    case 'AVILABLE_SOON':
      return 'Dostępny wkrótce'
    default:
      return ''
  }
}

export const parseDate = (date) => {
  date = new Date(date).toLocaleDateString()
  return date
}

export function translatePrizeOrderStatus (itemStatus) {
  switch (itemStatus) {
    case 'ORDERED':
      return 'Zamówiony'
    case 'PENDING':
      return 'W trakcie realizacji'
    case 'ISSUED':
      return 'Dostarczony'
    default:
      return ''
  }
}

export function translateOrderStatusForRequest (itemStatus) {
  switch (itemStatus) {
    case 'Zamówiony':
      return 'ORDERED'
    case 'W trakcie realizacji':
      return 'PENDING'
    case 'Dostarczony':
      return 'ISSUED'
    default:
      return ''
  }
}

export function calculateProductWithDecimal (product, price) {
  return new Decimal(new Decimal((product.baseUnitPerIndivisible)).toDecimalPlaces(2) * price).toDecimalPlaces(2).times(product.indivisibleQuantity).toDecimalPlaces(2).toNumber()
}

export const setParams = (from, to, rows, pathPhrase, pathId, text, expandedKeys, waterResistance, lock, spacing, color, thickness, abrasionClass, headShape, socket, profile, length, antibacterial, collection, thread, type, structure, moistureResistance, openingAngle, material, diameter, kit, width, producer, bearingCapacity, silentClosing, height, colorOfLight, powerFactor, hiddenWiring, symbol, intendedUse, guaranteePeriod, price, availability, isAvailability, sort) => {
  let keys
  let sortType
  if (expandedKeys) {
    keys = Object.keys(expandedKeys)
  }
  if (sort) {
    switch (sort.value.name) {
      case 'availability':
        if (sort.value.order === 'asc') {
          sortType = 'AVAILABILITY_ASC'
          break
        } else {
          sortType = 'AVAILABILITY_DESC'
          break
        }
      case 'price':
        if (sort.value.order === 'asc') {
          sortType = 'PRICE_ASC'
          break
        } else {
          sortType = 'PRICE_DESC'
          break
        }
      case 'effectiveName':
        if (sort.value.order === 'asc') {
          sortType = 'NAME_ASC'
          break
        } else {
          sortType = 'NAME_DESC'
          break
        }
      default :
        sortType = 'DEFAULT'
    }
  }

  router.replace({ name: 'Products', query: { from: from, phrase: text, pathPhrase: pathPhrase, pathId: pathId, to: to, rows: rows, expanded: JSON.stringify(keys), waterResistance: waterResistance, lock: lock, spacing: JSON.stringify(spacing), color: color, thickness: JSON.stringify(thickness), abrasionClass: abrasionClass, headShape: headShape, socket: socket, profile: profile, length: JSON.stringify(length), antibacterial: antibacterial, collection: collection, thread: thread, type: type, structure: structure, moistureResistance: moistureResistance, openingAngle: JSON.stringify(openingAngle), material: material, diameter: JSON.stringify(diameter), kit: kit, width: JSON.stringify(width), producer: producer, bearingCapacity: JSON.stringify(bearingCapacity), silentClosing: silentClosing, height: JSON.stringify(height), colorOfLight: colorOfLight, powerFactor: JSON.stringify(powerFactor), hiddenWiring: hiddenWiring, symbol: symbol, intendedUse: intendedUse, guaranteePeriod: JSON.stringify(guaranteePeriod), price: JSON.stringify(price), availability: JSON.stringify(availability), isAvailability: JSON.stringify(isAvailability), sort: sortType } })
}

export function parseBytes (bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function parseArticleDate (date) {
  if (date !== undefined) {
    return new Date(date).toLocaleString()
  }
}

export function parseDateArray (date) {
  if (date !== undefined) {
    // const newDate = new Date(date[0], date[1] - 1, date[2], date[3], date[4]).toLocaleDateString() + ', ' + new Date(date[0], date[1] - 1, date[2], date[3], date[4], date[5]).toLocaleTimeString()
    return new Date(date[0], date[1] - 1, date[2], date[3], date[4]).toLocaleDateString() + ', ' + new Date(date[0], date[1] - 1, date[2], date[3], date[4], date[5]).toLocaleTimeString()
    // return date[2] + '-' + date[1] + '-' + date[0] + ' ' + date[3] + ':' + date[4] + ':' + date[5]
  }
}

export function parseUserRoles (rolesList) {
  if (rolesList.includes('admin')) {
    return 'Administrator'
  } else {
    return 'Klient'
  }
}

export function setRole (rolesList) {
  if (rolesList.includes('super_admin')) {
    return userRoleInterfaceEnum.SUPER_ADMIN
  } else if (rolesList.includes('admin') && !rolesList.includes('super_admin')) {
    return userRoleInterfaceEnum.ADMIN
  } else {
    return userRoleInterfaceEnum.USER
  }
}
export function newsletterCategoriesListToMap (map) {
  const categoriesMap = {}
  for (const category of map) {
    const subcategoryList = []
    category.subcategories.forEach(singleSubcategory => {
      if (singleSubcategory.id === singleSubcategory.subcategory) {
        subcategoryList.push({ subcategory: singleSubcategory.subcategory })
      } else {
        subcategoryList.push({ id: singleSubcategory.id, subcategory: singleSubcategory.subcategory })
      }
    })
    categoriesMap[category.name] = subcategoryList
  }
  return {
    categories: categoriesMap
  }
}

export function parseUserSubscriptionsMap (subMap) {
  const subcatList = []
  for (const [key, value] of Object.entries(subMap.categories)) {
    console.log(key)
    for (const singleSubcategory of value) {
      if (singleSubcategory.id !== null) {
        subcatList.push(singleSubcategory.id)
      }
    }
  }
  return subcatList
}

export function parseDropdownMapToList (selectedMap) {
  const selectedItemList = []
  for (let i = 0; i < selectedMap.length; i++) {
    selectedItemList.push(selectedMap[i].code)
  }
  return selectedItemList
}

export function parseDropdownListToMap (selectedList) {
  const selectedItemMap = []
  for (let i = 0; i < selectedList.length; i++) {
    if (selectedList[i] === 'NewsletterCategory.Furniture') {
      selectedItemMap.push({
        name: 'Meblowy',
        code: 'NewsletterCategory.Furniture'
      })
    } else if (selectedList[i] === 'NewsletterCategory.Building') {
      selectedItemMap.push({
        name: 'Budowlany',
        code: 'NewsletterCategory.Building'
      })
    }
  }
  return selectedItemMap
}

export function sanitizeQuillHtml (dirtyHTML) {
  return sanitizeHtml(dirtyHTML, {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 's', 'u', 'img', 'ol', 'li', 'ul', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span'],
    allowedAttributes: {
      a: ['href'],
      img: ['src'],
      p: ['style'],
      span: ['style']
    },
    allowedSchemesByTag: {
      img: ['data']
    },
    allowedStyles: {
      '*': {
        color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/]
      },
      p: {
        'font-size': [/^\d+rem$/]
      }
    },
    allowedClasses: {
      '*': ['ql-align-center', 'ql-align-right', 'ql-align-justify', 'ql-align-center', 'ql-indent-1', 'ql-indent-2', 'ql-indent-3', 'ql-indent-4', 'ql-indent-5', 'ql-indent-6', 'ql-indent-7', 'ql-indent-8']
    }
  })
}
